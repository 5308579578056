body { background-color: #ffffff; }
body { color: var(--clr-16933); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-16933);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 35px;

}
}
h2 {
color: var(--clr-16933);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h3 {
color: var(--clr-16933);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 25px;

}
}
h4 {
color: var(--clr-16933);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
}
h5 {
color: var(--clr-16933);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-16934);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 22px;

}
.button {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$large-up} {
font-size: 20px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 22px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-16933);}
/* block:1 */
.MES1 {
background-color: var(--clr-16928);
color: #000000;
padding: 10px;

 }
.MES1 {
background-color: var(--clr-16928);
color: #000000;
padding: 10px;

h1.MEC1 { font-size: 10.4px; }
h1.MEC1 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC1 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC1 { font-size: 8.8px; }
h2.MEC1 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC1 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC1 { font-size: 8px; }
h3.MEC1 { @media #{$medium-up} { font-size: 15px; }; }
h3.MEC1 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC1 { font-size: 7.2px; }
h4.MEC1 { @media #{$medium-up} { font-size: 13.2px; }; }
h4.MEC1 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC1 { font-size: 6.8px; }
h5.MEC1 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC1 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC1 { font-size: 8.8px; }
h6.MEC1 { @media #{$medium-up} { font-size: 13.2px; }; }
h6.MEC1 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC1{
color: #000000;
}
/* footer:2 */
.MES2 {
background-color: var(--clr-16928);
color: var(--clr-16929);
padding: 10px 0;

 }
.MES2 {
background-color: var(--clr-16928);
color: var(--clr-16929);
padding: 10px 0;

h1.MEC2 { font-size: 23.4px; }
h1.MEC2 { @media #{$medium-up} { font-size: 38.5px; }; }
h1.MEC2 { @media #{$large-up} { font-size: 52.5px; }; }
h2.MEC2 { font-size: 19.8px; }
h2.MEC2 { @media #{$medium-up} { font-size: 33px; }; }
h2.MEC2 { @media #{$large-up} { font-size: 45px; }; }
h3.MEC2 { font-size: 18px; }
h3.MEC2 { @media #{$medium-up} { font-size: 27.5px; }; }
h3.MEC2 { @media #{$large-up} { font-size: 37.5px; }; }
h4.MEC2 { font-size: 16.2px; }
h4.MEC2 { @media #{$medium-up} { font-size: 24.2px; }; }
h4.MEC2 { @media #{$large-up} { font-size: 33px; }; }
h5.MEC2 { font-size: 15.3px; }
h5.MEC2 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC2 { @media #{$large-up} { font-size: 30px; }; }
h6.MEC2 { font-size: 19.8px; }
h6.MEC2 { @media #{$medium-up} { font-size: 24.2px; }; }
h6.MEC2 { @media #{$large-up} { font-size: 33px; }; }
 }
cite.MEC2{
color: var(--clr-16929);
}
/* test:3 */
.MES3 {
background-color: #000000;
color: #ffffff;
padding: 10px;

 }
.MES3 {
background-color: #000000;
color: #ffffff;
padding: 10px;

h1.MEC3 { font-size: 10.4px; }
h1.MEC3 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC3 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC3 { font-size: 8.8px; }
h2.MEC3 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC3 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC3 { font-size: 8px; }
h3.MEC3 { @media #{$medium-up} { font-size: 15px; }; }
h3.MEC3 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC3 { font-size: 7.2px; }
h4.MEC3 { @media #{$medium-up} { font-size: 13.2px; }; }
h4.MEC3 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC3 { font-size: 6.8px; }
h5.MEC3 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC3 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC3 { font-size: 8.8px; }
h6.MEC3 { @media #{$medium-up} { font-size: 13.2px; }; }
h6.MEC3 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC3{
color: #ffffff;
}
/* test2:4 */
.MES4 {
background-color: var(--clr-16929);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 10px 20px;

}
@media #{$large-up} {
padding: 10px 30px;

}
 }
.MES4 {
background-color: var(--clr-16929);
color: #ffffff;
padding: 10px;

@media #{$medium-up} {
padding: 10px 20px;

}
@media #{$large-up} {
padding: 10px 30px;

}
h1.MEC4 { font-size: 10.4px; }
h1.MEC4 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC4 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC4 { font-size: 8.8px; }
h2.MEC4 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC4 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC4 { font-size: 8px; }
h3.MEC4 { @media #{$medium-up} { font-size: 15px; }; }
h3.MEC4 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC4 { font-size: 7.2px; }
h4.MEC4 { @media #{$medium-up} { font-size: 13.2px; }; }
h4.MEC4 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC4 { font-size: 6.8px; }
h5.MEC4 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC4 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC4 { font-size: 8.8px; }
h6.MEC4 { @media #{$medium-up} { font-size: 13.2px; }; }
h6.MEC4 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC4{
color: #ffffff;
}
/* Home Text:5 */
.MES5 {
background-color: #ffffff;
color: #000000;
padding: 0 10px;

@media #{$medium-up} {
padding: 0 30px;

}
@media #{$large-up} {
padding: 0 50px;

}
 }
.MES5 {
background-color: #ffffff;
color: #000000;
padding: 0 10px;

@media #{$medium-up} {
padding: 0 30px;

}
@media #{$large-up} {
padding: 0 50px;

}
h1.MEC5 { font-size: 10.4px; }
h1.MEC5 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC5 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC5 { font-size: 8.8px; }
h2.MEC5 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC5 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC5 { font-size: 8px; }
h3.MEC5 { @media #{$medium-up} { font-size: 15px; }; }
h3.MEC5 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC5 { font-size: 7.2px; }
h4.MEC5 { @media #{$medium-up} { font-size: 13.2px; }; }
h4.MEC5 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC5 { font-size: 6.8px; }
h5.MEC5 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC5 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC5 { font-size: 8.8px; }
h6.MEC5 { @media #{$medium-up} { font-size: 13.2px; }; }
h6.MEC5 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC5{
color: #000000;
}
/* testimonial:6 */
.MES6 {
background-color: var(--clr-16930);
color: var(--clr-16929);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 20px;

@media #{$medium-up} {
padding: 15px 10px;

}
@media #{$large-up} {
padding: 15px 20px;

}
 }
.MES6 {
background-color: var(--clr-16930);
color: var(--clr-16929);
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 20px;

@media #{$medium-up} {
padding: 15px 10px;

}
@media #{$large-up} {
padding: 15px 20px;

}
h1.MEC6 { font-size: 10.4px; }
h1.MEC6 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC6 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC6 { font-size: 8.8px; }
h2.MEC6 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC6 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC6 { font-size: 8px; }
h3.MEC6 { @media #{$medium-up} { font-size: 15px; }; }
h3.MEC6 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC6 { font-size: 7.2px; }
h4.MEC6 { @media #{$medium-up} { font-size: 13.2px; }; }
h4.MEC6 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC6 { font-size: 6.8px; }
h5.MEC6 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC6 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC6 { font-size: 8.8px; }
h6.MEC6 { @media #{$medium-up} { font-size: 13.2px; }; }
h6.MEC6 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC6{
color: var(--clr-16929);
}
/* dark:7 */
.MES7 {
background-color: var(--clr-16929);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px 40px;

}
 }
.MES7 {
background-color: var(--clr-16929);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px 40px;

}
h1.MEC7 { font-size: 10.4px; }
h1.MEC7 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC7 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC7 { font-size: 8.8px; }
h2.MEC7 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC7 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC7 { font-size: 8px; }
h3.MEC7 { @media #{$medium-up} { font-size: 15px; }; }
h3.MEC7 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC7 { font-size: 7.2px; }
h4.MEC7 { @media #{$medium-up} { font-size: 13.2px; }; }
h4.MEC7 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC7 { font-size: 6.8px; }
h5.MEC7 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC7 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC7 { font-size: 8.8px; }
h6.MEC7 { @media #{$medium-up} { font-size: 13.2px; }; }
h6.MEC7 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC7{
color: #ffffff;
}
/* green button:8 */
.MES8 {
background-color: var(--clr-16928);
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px;

@media #{$large-up} {
padding: 20px 30px;

}
 }
/* Top Strip:9 */
.MES9 {
background-color: var(--clr-16928);
color: #ffffff;
padding: 8px 0;

@media #{$medium-up} {
padding: 16px 0;

}
 }
.MES9 {
background-color: var(--clr-16928);
color: #ffffff;
padding: 8px 0;

@media #{$medium-up} {
padding: 16px 0;

}
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: #ffffff;
 }
h1.MEC9 { font-size: 22.1px; }
h1.MEC9 { @media #{$medium-up} { font-size: 35px; }; }
h2.MEC9 { font-size: 18.7px; }
h2.MEC9 { @media #{$medium-up} { font-size: 30px; }; }
h3.MEC9 { font-size: 17px; }
h3.MEC9 { @media #{$medium-up} { font-size: 25px; }; }
h4.MEC9 { font-size: 15.3px; }
h4.MEC9 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC9 { font-size: 14.45px; }
h5.MEC9 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC9 { font-size: 18.7px; }
h6.MEC9 { @media #{$medium-up} { font-size: 22px; }; }
 }
cite.MEC9{
color: #ffffff;
}
/* test panel:10 */
.MES10 {
background-color: #ffffff;
color: #000000;
padding: 10px;

 }
.MES10 {
background-color: #ffffff;
color: #000000;
padding: 10px;

h1.MEC10 { font-size: 10.4px; }
h1.MEC10 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC10 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC10 { font-size: 8.8px; }
h2.MEC10 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC10 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC10 { font-size: 8px; }
h3.MEC10 { @media #{$medium-up} { font-size: 15px; }; }
h3.MEC10 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC10 { font-size: 7.2px; }
h4.MEC10 { @media #{$medium-up} { font-size: 13.2px; }; }
h4.MEC10 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC10 { font-size: 6.8px; }
h5.MEC10 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC10 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC10 { font-size: 8.8px; }
h6.MEC10 { @media #{$medium-up} { font-size: 13.2px; }; }
h6.MEC10 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC10{
color: #000000;
}
/* Orange pop out:11 */
.MES11 {
background-color: var(--clr-16931);
color: #ffffff;
padding: 10px;

 }
.MES11 {
background-color: var(--clr-16931);
color: #ffffff;
padding: 10px;

 }
cite.MEC11{
color: #ffffff;
}
/* Raspberry pop out:12 */
.MES12 {
background-color: var(--clr-16932);
color: #ffffff;
padding: 10px;

 }
.MES12 {
background-color: var(--clr-16932);
color: #ffffff;
padding: 10px;

h1.MEC12 { font-size: 10.4px; }
h1.MEC12 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC12 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC12 { font-size: 8.8px; }
h2.MEC12 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC12 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC12 { font-size: 8px; }
h3.MEC12 { @media #{$medium-up} { font-size: 15px; }; }
h3.MEC12 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC12 { font-size: 7.2px; }
h4.MEC12 { @media #{$medium-up} { font-size: 13.2px; }; }
h4.MEC12 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC12 { font-size: 6.8px; }
h5.MEC12 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC12 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC12 { font-size: 8.8px; }
h6.MEC12 { @media #{$medium-up} { font-size: 13.2px; }; }
h6.MEC12 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC12{
color: #ffffff;
}
/* Folio:25 */
.MES25 {
background-color: var(--clr-16928);
color: #ffffff;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
@media #{$medium-up} {
padding: 0;

}
 }
.MES25 {
background-color: var(--clr-16928);
color: #ffffff;
border-radius: 12px;
-webkit-transform: translateZ(0);
overflow: hidden;
@media #{$medium-up} {
padding: 0;

}
h1.MEC25 { font-size: 15.6px; }
h1.MEC25 { @media #{$large-up} { font-size: 35px; }; }
h2.MEC25 { font-size: 13.2px; }
h2.MEC25 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC25 { font-size: 12px; }
h3.MEC25 { @media #{$large-up} { font-size: 25px; }; }
h4.MEC25 { font-size: 10.8px; }
h4.MEC25 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC25 { font-size: 10.2px; }
h5.MEC25 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC25 { font-size: 13.2px; }
h6.MEC25 { @media #{$large-up} { font-size: 22px; }; }
 }
cite.MEC25{
color: #ffffff;
}
/* Primary:27 */
.MES27 {
background-color: var(--clr-16935);
color: #ffffff;
 }
/* Primary:28 */
.MES28 {
background-color: var(--clr-16935);
color: #ffffff;
 }
.MES28 {
background-color: var(--clr-16935);
color: #ffffff;
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
cite.MEC28{
color: #ffffff;
}
/* Secondary:29 */
.MES29 {
background-color: var(--clr-16934);
color: #ffffff;
 }
/* Secondary:30 */
.MES30 {
background-color: var(--clr-16934);
color: #ffffff;
 }
.MES30 {
background-color: var(--clr-16934);
color: #ffffff;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
 }
cite.MEC30{
color: #ffffff;
}
/* Light:31 */
.MES31 {
background-color: var(--clr-16936);
color: var(--clr-16937);
 }
/* Light:32 */
.MES32 {
background-color: var(--clr-16936);
color: var(--clr-16937);
 }
.MES32 {
background-color: var(--clr-16936);
color: var(--clr-16937);
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: var(--clr-16937);
 }
 }
cite.MEC32{
color: var(--clr-16937);
}
/* Dark:33 */
.MES33 {
background-color: var(--clr-16937);
color: var(--clr-16936);
 }
/* Dark:34 */
.MES34 {
background-color: var(--clr-16937);
color: var(--clr-16936);
 }
.MES34 {
background-color: var(--clr-16937);
color: var(--clr-16936);
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: var(--clr-16936);
 }
 }
cite.MEC34{
color: var(--clr-16936);
}
/* Slider Overlay:36 */
.MES36 {
background-color: var(--clr-16938);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES36 {
background-color: var(--clr-16938);
color: #ffffff;
padding: 10px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: #ffffff;
 }
 }
cite.MEC36{
color: #ffffff;
}
/* Upload Box:37 */
.me-block > .field-wrap.MES37 { 
& label {  }
 }
/* Accordion:38 */
details.MES38 {
& > summary{background-color: var(--clr-16935);
&:hover {background-color: var(--clr-16934);}
}
& > summary{padding: 10px 20px;}

& > article{padding: 15px;}

& > summary { color: #ffffff;
 }
 }
/* Shade 1:39 */
.MES39 {
background-color: var(--clr-16939);
color: #ffffff;
 }
/* Shade 1:40 */
.MES40 {
background-color: var(--clr-16939);
color: #ffffff;
 }
.MES40 {
background-color: var(--clr-16939);
color: #ffffff;
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: #ffffff;
 }
 }
cite.MEC40{
color: #ffffff;
}
/* Back button:41 */
.MES41 {
background-color: transparent;
&:hover {background-color: var(--clr-16935);}
color: var(--clr-16935);
&:hover { color: #ffffff;}
border-radius: 8px;
padding: 5px 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-16935);
 }
/* Drop box :42 */
.MES42 {
padding: 15px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-16939);
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
.MES42 {
padding: 15px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-16939);
border-top-style: dashed;
border-right-style: dashed;
border-bottom-style: dashed;
border-left-style: dashed;
 }
/* Shade 1:43 */
.MES43 {
background-color: var(--clr-16940);
color: #000000;
 }
.MES43 {
background-color: var(--clr-16940);
color: #000000;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: #000000;
 }
 }
cite.MEC43{
color: #000000;
}
/* Top menu:44 */
nav.me-Menu.MES44 {
& .menu-item.MEC44, & .menu-item.MEC44 > div.MEC44{ & > a.MEC44{color: #ffffff;
}
 &:hover > a.MEC44{color: var(--clr-16939);
}
 }
&.horizontal > .menu-item.MEC44 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC44 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC44 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC44 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 15px;}

& .sub-menu .menu-item a{padding: 15px 20px;}


& > .menu-item.MEC44.active { & > a{ color: var(--clr-16934);}
 }
&.horizontal .menu-item.MEC44:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #575757;}}
&.vertical .menu-item.MEC44:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #575757;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC44{background-color: #575757; &:hover {background-color: var(--clr-16929);}
}
& .menu-item.MEC44, & .menu-item.MEC44 > div.MEC44{ & > a.MEC44{color: #ffffff;
}
 &:hover > a.MEC44{color: #ffffff;
}
 }

}
}
 }
/* Responsive menu:45 */
nav.responsive-menu {
.menu-item.MEC45{background-color: var(--clr-16935);}
& .menu-item.MEC45, & .menu-item.MEC45 > div.menu-item-wrap{ & > a.MEC45, & > i{color: #ffffff;
text-align: left;
}
  }
& .menu-item.MEC45 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 2px 20px;}

& .sub-menu{ .sub-menu {}}

 }
/* PM LOGIN Button:46 */
.MES46 {
background-color: var(--clr-16928);
&:hover {background-color: var(--clr-16935);}
color: #ffffff;
&:hover { color: var(--clr-16934);}
border-radius: 12px;
padding: 10px 20px;

border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
/* Copyright:47 */
.MES47 {
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
.MES47 {
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
 }
cite.MEC47{
font-size: 12.8px;
@media #{$medium-up} {
font-size: 14.4px;
};
}
/* Header Dark:48 */
.MES48 {
background-color: var(--clr-16937);
color: var(--clr-16936);
border-width: 0 0 4px 0;
border-style: solid;
border-color: transparent transparent var(--clr-16935) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES48 {
background-color: var(--clr-16937);
color: var(--clr-16936);
border-width: 0 0 4px 0;
border-style: solid;
border-color: transparent transparent var(--clr-16935) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC48, h2.MEC48, h3.MEC48, h4.MEC48, h5.MEC48, h6.MEC48 { color: var(--clr-16936);
 }
 }
cite.MEC48{
color: var(--clr-16936);
}
/* Bottom Dark:49 */
.MES49 {
background-color: var(--clr-16937);
color: var(--clr-16936);
border-width: 4px 0 0 0;
border-style: solid;
border-color: var(--clr-16939) transparent transparent transparent;
 }
.MES49 {
background-color: var(--clr-16937);
color: var(--clr-16936);
border-width: 4px 0 0 0;
border-style: solid;
border-color: var(--clr-16939) transparent transparent transparent;
h1.MEC49, h2.MEC49, h3.MEC49, h4.MEC49, h5.MEC49, h6.MEC49 { color: var(--clr-16936);
 }
 }
cite.MEC49{
color: var(--clr-16936);
}
/* Page Accordion:50 */
details.MES50 {
 }
/* Slider home:52 */
.MES52 {
& .slider-arrow {color: #ffffff74;
font-size: 60px;
@media #{$medium-up} {
font-size: 59px;
};
@media #{$large-up} {
font-size: 59px;
};
} }
